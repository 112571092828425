import { css } from 'styled-components';

const Theme: any = {};

Theme.colors = {
  black: '#000000',
  blackTransparent: css`rgba(#000000, 0.3)`,
  gray: '#7d7d7d',
  grayTransparent: css`rgba(#7d7d7d, 0.3)`,
  darkGray: '#2d2d2d',
  code: '#272822',
  white: '#ffffff',
  orange: '#ed5d3e',
  blankYellow: '#f1dbbb',
  darkerBlue: '#1e193a',
  darkBlue: '#221d43',
};

Theme.typography = {
  firstFontFamily: '"Poppins", sans-serif',
  secondFontFamily: '"Orbitron", sans-serif',
  fontSize: '1rem',
  fontWeight: '300',
  lineHeight: '1.5',
};

export { Theme };

export type ThemeType = typeof Theme;
